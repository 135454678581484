/* Innocent */
@font-face {
  font-family: 'Clother-Light';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Clother-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Clother-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Clother-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Clother-Bold';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Clother-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Clother-Black';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Clother-Black.otf') format('opentype');
}
@font-face {
  font-family: 'ClotherVariable';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/ClotherVariable.ttf') format('truetype');
}